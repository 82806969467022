<template>
    <div>
        <v-row no-gutters>
            <v-col class="sidebar-cont" cols="12" md="3" xl="2">
                <v-navigation-drawer
                class="elevation-2 sidebar-agency"
                :permanent="$vuetify.breakpoint.mdAndUp"
                absolute
                v-model="sideNav1"
                :width="$vuetify.breakpoint.mdAndUp ? '100%' : 'fit-content'"
                height="100vh"
                >
                    <v-list>
                    <v-list-item>
                        <v-img max-width="80%" src="../../assets/logo.png"></v-img>
                    </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list
                    nav
                    dense
                    class="mx-5"
                    >
                        <v-list-item
                        active-class="side-nav_active"
                        exact
                        v-for="item in vendorRoutes" :key="item.route"
                        :to="item.route" link
                        class="text-capitalize my-5 px-10 py-3"
                        >
                            <v-list-item-icon>
                                <v-icon :color="$route.path === item.route ? 'primaryDark' : ''">{{item.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title><span class="text-h6" :class="$route.path === item.route ? 'primaryDark--text' : ''">{{item.name}}</span></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-navigation-drawer>
            </v-col>
            <v-col class="order-first order-md-2" cols="12" md="9" xl="10">
                <v-app-bar height="90" color="white">

                    <v-toolbar-title style="width: 350px;" class="d-none d-md-flex">
                        <v-text-field full-width placeholder="Search For Results..." outlined rounded type="text" hide-details>
                            <template v-slot:append>
                                <v-icon color="primary">mdi-magnify</v-icon>
                            </template>
                        </v-text-field>
                    </v-toolbar-title>

                    <v-img class="d-block d-md-none" max-width="100px" src="../../assets/logo.png"></v-img>

                    <v-spacer></v-spacer>

                    <v-menu
                    left
                    bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="mx-5 d-none d-md-block"
                        >
                            <v-icon>mdi-web</v-icon>
                            <span>EN</span>
                        </v-btn>
                        </template>

                        <v-list>
                        <v-list-item>
                            <v-list-item-title>English</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Arabic</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>French</v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-divider vertical></v-divider>

                    <v-badge
                    content="1"
                    :value="1"
                    color="pink"
                    overlap
                    bordered
                    class="mx-5"
                    >
                        <v-icon>mdi-bell</v-icon>
                    </v-badge>

                    <v-divider vertical></v-divider>

                    <v-btn text @click="logout">
                        <span class="d-none d-md-block">Logout</span>
                        <v-icon>mdi-logout</v-icon>
                    </v-btn>

                    <v-app-bar-nav-icon @click="sideNav1 = !sideNav1" class="d-block d-md-none"></v-app-bar-nav-icon>
                </v-app-bar>
                <v-container>
                    <v-breadcrumbs divider=">" large :items="items">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            class="text-subtitle-2 crumb-item paragraph--text"
                            :disabled="item.disabled"
                            :exact="true"
                            :to="item.path"
                            >
                                {{ item.name }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <router-view :key="$route.path"></router-view>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import auth from '@/web_services/auth'

export default {
  data () {
    return {
      sideNav1: true,
      breakpoint: true,
      vendorRoutes: [
        { name: 'dashboard', icon: 'mdi-home', route: '/vendors' },
        { name: 'trips', icon: 'mdi-airplane', route: '/vendors/trips' },
        { name: 'bookings', icon: 'mdi-file-document-outline', route: '/vendors/bookings' },
        { name: 'financial', icon: 'mdi-cash', route: '/vendors/finance' },
        { name: 'profile', icon: 'mdi-account', route: '/vendors/profile' }
      ]
    }
  },
  computed: {
    items () {
      const routeComps = this.$route.path.split('/').filter(v => !!v).filter(v => isNaN(v))
      const routes = []
      routeComps.forEach((element, i) => {
        routes.push({
          name: element,
          path: getPath(i)
        })
      })
      function getPath (i) {
        let path = ''
        if (i > 0) {
          for (let index = 0; index < i; index++) {
            path += '/' + routeComps[index]
          }
          path += '/' + routeComps[i]
        } else path = '/' + routeComps[i]
        return path
      }
      routes[routes.length - 1].disabled = true
      return routes
    }
  },
  methods: {
    removeUserData () {
      this.$store.dispatch('removeUser')
      this.$cookies.remove('gt_user_token')
      this.$cookies.remove('gt_user_role')
      this.$router.push({ path: '/auth' })
    },
    logout () {
      auth.logout().then(res => {
        this.removeUserData()
      }).catch(err => {
        if (err.response.status === 401) this.removeUserData()
      })
    }
  }
}
</script>

<style>
.side-nav_active.v-list-item {
    border-radius: 25px;
}
.side-nav_active::before {
    background-color: white;
}
.side-nav_active {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 10px;
}
.sidebar-agency {
    position: sticky;
    top: 0;
}
@media (max-width: 960px) {
    .sidebar-cont {
        position: absolute;
        top: 0;
    }
}
</style>
